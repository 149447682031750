<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
Tourism and Visa Guidelines         </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap ">
          <div class="mx-2 text-left">
              <p class="my-8">
             <b>  Immigration Guidelines: </b><br>
 If entering the Maldives as a tourist, you will be granted a 30-day visa upon arrival and your passport must be valid for a minimum of one month. 
   
                  
                   </p>

            <p class="my-8">
            However, if arriving by air, most airlines state that your passport should be valid for a minimum period of 6 months from the date of entry into the Maldives. 


<br>
The basic entry requirements:
            </p>  
            
             <p class="my-8">
             <ul class="list-disc">
<li>	A passport or a travel document with Machine Readable Zone (MRZ) with at least 1 month’s validity.</li>
<li> A pre-paid hotel booking/accommodation.</li>
<li> Sufficient funds to last for the intended period of stay in Maldives.</li>
<li> Confirmed onward/return tickets to home country or to country of residence (not applicable for valid resident permit holders).</li>
<li> Entry facilities to their onward destinations; for example, the visa.</li>
<li> Traveler Health Declaration (THD) must be filled in and submitted by all travelers travelling to and from Maldives, within 24 hours prior to their travel. You may submit the form electronically via http://imuga.immigration.gov.mv </li>
<li> Yellow Fever Vaccination Certificate, if applicable.</li>
<li> COVID-19 negative PCR test result, attached while completing Traveler Health Declaration form (sample taken 96 hours prior to departure to Maldives, counting from the first port of embarkation en-route to the Maldives).</li>



                 </ul>
            </p>


            <p class="my-8 mb-40"> 
                For more details click the link below.
              <br> <a href="https://immigration.gov.mv/tourist-visa/">
 https://immigration.gov.mv/tourist-visa/ </a>


                </p>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "GuidelinesPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
